<template>
    <section>
        <div id="menu_m">
            <div id="mobile_menu" @click="close_menu()"></div>
            <div id="logo"></div>
            <a href="https://t.me/SpeedServiceMobility_Chat"><div id="mobile_telegram"></div></a>

            <div id="menus">
                <div class="menu" @click="go_to_section('#section_home')">HOME</div>
                <div class="menu" @click="go_to_section('#section_ourvision')">VISION</div>
                <div class="menu" @click="go_to_section('#section_system')">SYSTEM</div>
                <div class="menu" @click="go_to_section('#section_roadmap')">ROADMAP</div>
                <div class="menu" @click="go_to_section('#section_footer')">CONTACT</div>
                <div class="menu" id="footer">
                    <div>
                        ©Copyright © 2023 All rights reserved<br />
                        by SSM,ltd
                    </div>
                </div>
            </div>
        </div>
        <div id="sticky_menu" class="desktoponly">
            <div id="menu_items">
                <div @click="go_to_section('#section_home')">HOME</div>
                <div @click="go_to_section('#section_ourvision')">VISION</div>
                <div @click="go_to_section('#section_system')">SYSTEM</div>
                <div @click="go_to_section('#section_roadmap')">ROADMAP</div>
                <div @click="go_to_section('#section_footer')">CONTACT</div>
            </div>
        </div>
        <div id="section_home" class="section">
            <div id="home_header" class="desktoponly">
                <div id="logo"></div>
                <div id="menus">
                    <div @click="go_to_section('#section_home')">HOME</div>
                    <div @click="go_to_section('#section_ourvision')">VISION</div>
                    <div @click="go_to_section('#section_system')">SYSTEM</div>
                    <div @click="go_to_section('#section_roadmap')">ROADMAP</div>
                    <div @click="go_to_section('#section_footer')">CONTACT</div>
                </div>
                <div id="community_buttons">
                    <button>KAKAO TALK</button>
                    <a href="https://t.me/SpeedServiceMobility_Chat"><button>TELEGRAM</button></a>
                </div>
            </div>
            <div id="home_header" class="mobileonly">
                <div id="mobile_menu" @click="open_menu()"></div>
                <div id="logo"></div>
                <a href="https://t.me/SpeedServiceMobility_Chat"><div id="mobile_telegram"></div></a>
            </div>
            <div id="home_content_background" class="desktoponly">
                <div id="home_over_car1"></div>
                <div id="home_over_car2"></div>
            </div>
            <div id="home_content">
                <span>전기차 산업의<br />생태계 구성은 SSM</span>
                <span class="desktoponly">
                    SSM은 전기차충전, 게임, NFT 등 다양한 산업을 연계하여<br />
                    이용자들과 함께 전기차산업의 생태계를 구성할 수 있습니다.
                </span>
                <span class="mobileonly">
                    SSM은 전기차충전, 게임, NFT 등<br />
                    다양한 산업을 연계하여 이용자들과 함께<br />
                    전기차산업의 생태계를 구성할 수 있습니다.
                </span>
                <a href="./download/whitepaper_ssm.pdf" download><button>백서 다운로드</button></a>
            </div>
            <div id="background"></div>
        </div>

        <div id="section_ourvision" class="section">
            <div id="title">Our Vision</div>
            <span>SSM은 4가지의 비전을 제시하고 있습니다.</span>
            <div id="ourvision_gradient" class="mobileonly"></div>
            <div id="visions" class="desktoponly">
                <div id="vision_element" v-for="(el, index) in ['충전기 부족 해결', '충전기 점유 해결', '충전기 플랫폼 로밍', '신재생 에너지 사용 촉진']" :key="index">
                    <div class="vision_picture" :style="{ 'background-image': 'url(./images/icon_vision' + (index + 1) + '.png)' }"></div>
                    <span>{{ el }}</span>
                </div>
            </div>
            <div id="visions" class="mobileolny">
                <swiper class="mySwiper mobileonly" @swiper="on_swiper" :slidesPerView="'auto'">
                    <swiper-slide v-for="(el, index) in ['충전기 부족 해결', '충전기 점유 해결', '충전기 플랫폼 로밍', '신재생 에너지<br>사용 촉진']" :key="index">
                        <div id="vision_element">
                            <div class="vision_picture" :style="{ 'background-image': 'url(./images/icon_vision' + (index + 1) + '.png)' }"></div>
                            <div class="text" v-html="el"></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <span class="desktoponly">
                전기차 생태계의 플레이어간 공유 가능,이를 통하여 충전기의 문제를 해결하며, <br />
                충전기의 사용을 독점적으로 사용하면 생태계 내에서 패널티가 부여하여 보다 건강한 인프라<br />
                이용을 가능하게 합니다. 또한 로밍서비스를 이용하여 연동문제 해결, 클린에너지 사용을 도모하며<br />
                보다 친환경적 생태계 유지에 도움을 줍니다.
            </span>
            <span class="mobileonly">
                전기차 생태계의 플레이어간 공유 가능,이를 통하여<br />
                충전기의 문제를 해결하며, 충전기의 사용을<br />
                독점적으로 사용하면 생태계 내에서 패널티가<br />
                부여하여 보다 건강한 인프라 이용을<br />
                가능하게 합니다. 또한 로밍서비스를 이용하여<br />
                연동문제 해결, 클린에너지 사용을 도모하며<br />
                보다 친환경적 생태계 유지에 도움을 줍니다.
            </span>
            <div id="vision_over"></div>
        </div>
        <div id="section_system" class="section desktoponly">
            <div id="title">Blockchain System</div>
            <img src="@/assets/images/image_blockchain_system.svg" width="1147" height="416.28" />
            <div id="sub_section">
                <span>SSM Token Sale</span>
                <span>
                    결제에 사용되는 SMP와 SSM은 현지 통화와<br />
                    페어링 되며, SMP는 가격이 고정되고 충전에<br />
                    사용되는 SSM의 수량은 SSM의 시장가격에 따라<br />
                    변동되며 전 세계 가상자산 거래소의 평균<br />
                    가격에 대한 API를 통해 산정이 됩니다.
                </span>
                <div id="seetoken" class="desktoponly">
                    See Token distribution
                    <img src="@/assets/images/arrow_distribution.svg" width="24" height="24" style="margin: 10px" />
                </div>
                <div id="picture_tokensale"></div>
                <div id="picture_tokensale_back"></div>
            </div>
        </div>
        <div id="section_system" class="section mobileonly">
            <div id="title">Blockchain System</div>
            <div id="picture_box">
                <div id="but_zoom" @click="open_zoom()"></div>
                <img src="@/assets/images/image_blockchain_system.svg" />
            </div>
            <div id="sub_section">
                <span>SSM Token Sale</span>
                <span class="desktoponly">
                    결제에 사용되는 SMP와 SSM은 현지 통화와 페어링<br />
                    되며, SMP는 가격이 고정되고 충전에 사용되는<br />
                    SSM의 수량은 SSM의 시장가격에 따라 변동되며<br />
                    전 세계 가상자산 거래소의 평균 가격에 대한<br />
                    API를 통해 산정이 됩니다.
                </span>
                <span class="mobileonly">
                    결제에 사용되는 SMP와 SSM은 현지 통화와<br />
                    페어링 되며, SMP는 가격이 고정되고 충전에<br />
                    사용되는 SSM의 수량은 SSM의 시장가격에 따라<br />
                    변동되며 전 세계 가상자산 거래소의 평균 가격에 대한<br />
                    API를 통해 산정이 됩니다.
                </span>

                <div id="seetoken">
                    See Token distribution
                    <img src="@/assets/images/arrow_distribution.svg" width="24" height="24" style="margin: 10px" class="desktoponly" />
                    <div id="seetoken_gradient" class="mobileonly"></div>
                </div>
                <div id="picture_tokensale">
                    <img src="@/assets/images/graph_mobile.png" width="100%" />
                </div>
                <div id="picture_tokensale_back" class="desktoponly"></div>
            </div>
        </div>
        <div id="section_roadmap" class="section">
            <div id="title">Roadmap</div>
            <div id="quarters">
                <div id="quarter">
                    <div id="quarter_title">Q4 2022</div>
                    <div class="quarter_text">SSM 플랫폼 적용</div>
                    <div class="quarter_text">전가차 제조사/충전기 범위 확대</div>
                    <div class="quarter_text">SSM 서비스 확대를 위한 NFT<br />선물거래 등 연동</div>
                    <div class="quarter_text">SSM 토큰 투자 유치</div>
                    <div class="quarter_text">SSM 토큰 시범 사업</div>
                </div>
                <div id="quarter">
                    <div id="quarter_title">Q1 2023</div>
                    <div class="quarter_text">전기차 관련 해외산업 참여</div>
                    <div class="quarter_text">해당국가 산업부 협동</div>
                    <div class="quarter_text">국내 금융사 제휴카드 참여</div>
                    <div class="quarter_text">국내 차량 제조사 실증사업 참여</div>
                </div>
                <div id="quarter">
                    <div id="quarter_title">Q2 2023</div>
                    <div class="quarter_text">전기차 관련 블록체인 실증 <br/>
                    사업 참여</div>
                    <div class="quarter_text">충전 사업 진행 및 게임과<br />NFT 마켓 플레이스 출시</div>
                    <div class="quarter_text">
                        NFT 마켓플레이스 내 거래 및<br />
                        유통을 위한 SSM 토큰 사용 확대
                    </div>
                </div>
                <div id="quarter">
                    <div id="quarter_title">Q3 2023</div>
                    <div class="quarter_text">탄소배출권 사업 참여</div>
                    <div class="quarter_text">P2E 게임 거래 시장 활성화 <br/>및 사업화</div>
                    <div class="quarter_text">전기차 충전 기술 연동을 위한 <br />빅데이터 서비스 제공</div>
                    <div class="quarter_text">전기차 정비 관련 솔루션 제휴 확장</div>
                </div>
            </div>
        </div>
        <div id="devider"></div>
        <div id="section_footer" class="section">
            <img src="@/assets/images/logo_footer.svg" width="139" height="84" class="desktoponly" />
            <img src="@/assets/images/logo_footer.svg" width="113" height="68" class="mobileonly" />
            <!-- <div>Email : vhstkak@naver.com</div> -->
            <div id="telegram">
                <div>Telegram :</div>
                <a href="https://t.me/SpeedServiceMobility_Chat"><div id="button"><span class="desktoponly">TELEGRAM</span>&nbsp;바로 입장하기</div></a>
            </div>
        </div>
        <div id="devider_footer">©Copyright © 2023 All rights reserved by SSM,ltd</div>
        <div id="zoom_image" class="mobileonly">
            <div id="zoom_image_container">
                <img src="@/assets/images/image_blockchain_system.svg" width="300" height="400" />
            </div>
            <div id="zoom_close" @click="close_zoom()"></div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/vue";
import Panzoom from "@panzoom/panzoom";

let b_show_sticky_menu = false;
export default {
    name: "App",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiper: null,
            panzoom: null
        };
    },

    mounted() {
        document.addEventListener("scroll", () => {
            var scroll = $(window).scrollTop();
            // console.log(scroll);
            if ($(window).width() < 1000) return;
            if (scroll > 120 && !b_show_sticky_menu) this.show_sticky_menu();
            if (scroll < 120 && b_show_sticky_menu) this.hide_sticky_menu();
        });
    },
    methods: {
        open_zoom() {
            $("div#zoom_image").fadeIn();
            this.panzoom = Panzoom(document.querySelector("div#zoom_image_container"), { contain: "outside" });
        },
        close_zoom() {
            $("div#zoom_image").fadeOut();
            this.panzoom.destroy();
        },

        download(link) {
            window.open(link);
        },
        openlink(link) {
            window.open(link);
        },
        open_menu() {
            $("div#menu_m").animate({
                left: 0
            });
        },
        close_menu() {
            $("div#menu_m").animate({
                left: "-100%"
            });
        },

        on_swiper(s) {
            this.swiper = s;
        },

        go_to_section(section_id) {
            this.close_menu();
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(section_id).offset().top
                },
                500
            );
        },

        show_sticky_menu() {
            b_show_sticky_menu = true;
            $("#sticky_menu").fadeIn(200);
            // console.log("show");
        },

        hide_sticky_menu() {
            b_show_sticky_menu = false;
            $("#sticky_menu").fadeOut(200);
            // console.log("hide");
        },

        on_mouseover_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "visible"
            });
            // console.log("on", pid);
        },

        on_mouseout_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "hidden"
            });
            // console.log($(div_id + " div#sub_div"), "off", pid);
        },
        on_but_slide_next() {},
        on_but_slide_prev() {
            // this.swiper.slidePrev();
        }
    }
};
</script>
<style lang="scss" src="@/assets/css/common.scss"></style>
<style lang="scss" src="@/assets/css/desktop.scss"></style>
<style lang="scss" src="@/assets/css/mobile.scss"></style>
